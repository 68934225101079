/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */

 import Vue from 'vue';

 export const getAuxiliaryZtableValueByName = (table_name_id) => {
   try {
     return new Promise((resolve, reject) => {
       Vue.prototype.$api.auxiliarytable
         .getAuxiliaryZtableNameId(table_name_id)
         .then(({ data }) => {
           resolve(data);
         })
         .catch((error) => {
           console.log(error)
           reject(error)
         });
       });
   } catch (err) {
     console.warn('[vuex.user] GetRoles', err);
   }
 }

  export const formatDateDDMMYYYY = (date) => {
    if (!date) return null
    var arr= date.split('-'),
              year = arr[0],
              month =  arr[1],
              day=  arr[2];
    if(year.length ===4)
    return `${day}-${month}-${year}`;
    return date;
  }

  export const parseDateYYYYMMDD = (date) => {
    if (!date) return null
    var arr= date.split('-'),
              day = arr[0],
              month =  arr[1],
              year =  arr[2];
              if(year.length ===4)
    return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`
    return date;
  }

  export const todayDate = () => {
    const today = new Date();
    return today.toISOString().substring(0,10)
  }

  export const tomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    return tomorrow.toISOString().substring(0,10)
  }

  export const oneWeekAgoDate = () => {
    var myDate = new Date();
    var newDate = new Date(myDate.getTime() - (60*60*24*7*1000));
    return newDate.toISOString().substring(0,10)
  }

 export const changedFormData = (model, originModel) => {
  return Object.keys( model ).reduce( ( formData, field ) => {
      if ( model[ field ] !== originModel[ field ] ) {
        formData[ field ] = model[ field ];
      }
      return formData;
    }, {} );
  }

export function getLineChartOption(chartLegend, data, type, lineChartData = {}) {
  let series = [];
  if (chartLegend[0] && lineChartData.sumOfAllDoc) {
    series.push({
      name: chartLegend[0],
      barGap: 0,
      itemStyle: {
        normal: {
          color: 'blue',
          lineStyle: {
            color: 'blue',
            width: 2,
          },
        },
      },
      smooth: true,
      type: type,
      data: lineChartData.sumOfAllDoc,
      animationDuration: 2800,
      animationEasing: 'cubicInOut',
    })
  }

  if (chartLegend[1] && lineChartData.sumOfClosedDoc) {
    series.push({
      name: chartLegend[1],
      smooth: true,
      barGap: 0,
      type: type,
      itemStyle: {
        normal: {
          color: 'green',
          lineStyle: {
            color: 'green',
            width: 2,
          },
          areaStyle: {
            color: '#f3f8ff',
          },
        },
      },
      data: lineChartData.sumOfClosedDoc,
      animationDuration: 2800,
      animationEasing: 'quadraticOut',
    })
  }

  if (chartLegend[2] && lineChartData.sumOfCancelledDoc) {
    series.push({
      name: chartLegend[2],
      smooth: true,
      type: type,
      barGap: 0,
      itemStyle: {
        normal: {
          color: 'red',
          lineStyle: {
            color: 'red',
            width: 2,
          },
          areaStyle: {
            color: '#f3f8ff',
          },
        },
      },
      data: lineChartData.sumOfCancelledDoc,
      animationDuration: 2800,
      animationEasing: 'quadraticOut',
    })
  }

  return {
    xAxis: {
      data: data,
      type: 'category',
      boundaryGap: false,
      axisTick: {
        length: 5
      },
      axisLabel: {
        fontSize: 10,
        margin: 8
      }
    },
    grid: {
      left: 75,
      right: 35,
      bottom: 20,
      top: 50,
      containLabel: false,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
      padding: [5, 10],
    },
    yAxis: {
      type: 'value',
      axisTick: {
        show: false,
      },
      splitNumber: 8
    },
    legend: {
      data: chartLegend,
    },
    series: series,
  };
}

function debounce(func, wait, immediate) {
  let timeout;
  let args;
  let context;
  let timestamp;
  let result;

  const later = () => {
    // According to the last trigger interval
    const last = +new Date() - timestamp;

    // The last time the wrapped function was called,
    // the interval is last less than the set interval. wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // If it is set to immediate===true, since the start boundary has already been called,
      // there is no need to call it here.
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args == null;
      }
    }
  };

  return (...args1) => {
    context = this;
    timestamp = +new Date();
    const callNow = immediate && !timeout;
    // If the delay does not exist, reset the delay
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args1);
      context = args1 == null;
    }
    return result;
  };
}

export default debounce;
